// Generated by Framer (4fadce2)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/kUqbV70G9";

const enabledGestures = {ITzs_nerx: {hover: true}};

const cycleOrder = ["ITzs_nerx", "dkv7ja0lV"];

const serializationHash = "framer-vJNZQ"

const variantClassNames = {dkv7ja0lV: "framer-v-1reqzl4", ITzs_nerx: "framer-v-195yg0x"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Active: "dkv7ja0lV", Inactive: "ITzs_nerx"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, memcJF5UE: title ?? props.memcJF5UE ?? "Description", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "ITzs_nerx"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, memcJF5UE, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "ITzs_nerx", enabledGestures, transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "dkv7ja0lV") return true
return false
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = [sharedStyle.className]

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-195yg0x", className, classNames)} data-framer-name={"Inactive"} initial={variant} layoutDependency={layoutDependency} layoutId={"ITzs_nerx"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({"ITzs_nerx-hover": {"data-framer-name": undefined}, dkv7ja0lV: {"data-framer-name": "Active"}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 className={"framer-styles-preset-18q7ed"} data-styles-preset={"kUqbV70G9"} style={{"--framer-text-color": "var(--extracted-gdpscs, var(--token-32a85a80-d312-468e-9679-2600c480ecba, rgb(40, 40, 40)))"}}>Description</motion.h1></React.Fragment>} className={"framer-ydo08l"} data-framer-name={"Get started"} layoutDependency={layoutDependency} layoutId={"qHyxKPJna"} style={{"--extracted-gdpscs": "var(--token-32a85a80-d312-468e-9679-2600c480ecba, rgb(40, 40, 40))", "--framer-paragraph-spacing": "0px", opacity: 0.6}} text={memcJF5UE} variants={{"ITzs_nerx-hover": {opacity: 1}, dkv7ja0lV: {opacity: 1}}} verticalAlignment={"top"} withExternalLayout/>{isDisplayed() && (<motion.div className={"framer-alzz8c"} data-framer-name={"Underline"} layoutDependency={layoutDependency} layoutId={"wNQa2BGkS"} style={{backgroundColor: "var(--token-32a85a80-d312-468e-9679-2600c480ecba, rgb(40, 40, 40))"}}/>)}</motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-vJNZQ[data-border=\"true\"]::after, .framer-vJNZQ [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-vJNZQ.framer-1fp3jnd, .framer-vJNZQ .framer-1fp3jnd { display: block; }", ".framer-vJNZQ.framer-195yg0x { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 8px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-vJNZQ .framer-ydo08l { flex: none; height: auto; position: relative; white-space: pre; width: auto; z-index: 2; }", ".framer-vJNZQ .framer-alzz8c { bottom: 0px; flex: none; height: 1px; left: calc(50.00000000000002% - 100% / 2); overflow: visible; position: absolute; width: 100%; z-index: 1; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-vJNZQ.framer-195yg0x { gap: 0px; } .framer-vJNZQ.framer-195yg0x > * { margin: 0px; margin-left: calc(8px / 2); margin-right: calc(8px / 2); } .framer-vJNZQ.framer-195yg0x > :first-child { margin-left: 0px; } .framer-vJNZQ.framer-195yg0x > :last-child { margin-right: 0px; } }", ...sharedStyle.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 34
 * @framerIntrinsicWidth 112
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"dkv7ja0lV":{"layout":["auto","auto"]},"ye9fvUlJ1":{"layout":["auto","auto"]}}}
 * @framerVariables {"memcJF5UE":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const Frameryvif9skgv: React.ComponentType<Props> = withCSS(Component, css, "framer-vJNZQ") as typeof Component;
export default Frameryvif9skgv;

Frameryvif9skgv.displayName = "01-Components/Tab Menu Link";

Frameryvif9skgv.defaultProps = {height: 34, width: 112};

addPropertyControls(Frameryvif9skgv, {variant: {options: ["ITzs_nerx", "dkv7ja0lV"], optionTitles: ["Inactive", "Active"], title: "Variant", type: ControlType.Enum}, memcJF5UE: {defaultValue: "Description", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(Frameryvif9skgv, [...sharedStyle.fonts])